<template>
  <div class="relative dynamic-field">
    <lf-text-editor
      ref="textEditorComponentRef"
      content-type="html"
      :value="modelValue"
      :name="name"
      :toolbar="showToolbar ? dynamicToolbar : []"
      :modules="modules"
      :placeholder="placeholder"
      @update:content="handleValueUpdate"
      @selection:change="handleSelectionChange"
      @editor:ready="handlePreparedEditor"
    />
    <dynamic-field-link-modal
      v-if="showLinkEditor"
      ref="modalRef"
      :value="linkEditorValue"
      :position="linkModalPosition"
      @value:save="saveLink"
      @modal:close="closeLinkModal"
      @link:remove="removeLink"
    />
  </div>
</template>
<script setup lang="ts">
import Mention from "quill-mention";
import ImageUploader from "quill-image-uploader";
import LfTextEditor from "@/components/ui/LfTextEditor.vue";
import DynamicFieldLinkModal from "@/components/DynamicFieldLinkModal.vue";
import { computed, ref, type Ref } from "vue";
import type { EmailTemplateDynamicField } from "@/models/options";
import { WYSIWYG_TOOLBAR_DYNAMIC } from "@/helpers/constants";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import {
  removeAttributesFromPayload,
  removeDenotation
} from "@/helpers/textEditor";
import type { Delta } from "@vueup/vue-quill";
import { onClickOutside } from "@vueuse/core";
import type { MaybeElement } from "@vueuse/core";
import compact from "lodash/compact";

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: ""
  },
  showToolbar: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  canUploadImage: {
    type: Boolean
  }
});

const emit = defineEmits<{
  "update:modelValue": [value: string];
  "file:upload": [File];
}>();

const { t } = useI18n();
const { getters } = useStore();

const modalRef = ref<InstanceType<typeof DynamicFieldLinkModal> | null>(null);

const MAX_TAGS_DROPDOWN_ELEMENTS = 13;
const DROPDOWN_TAG_HEIGHT = 30;
const maxTagsDropdownHeight = MAX_TAGS_DROPDOWN_ELEMENTS * DROPDOWN_TAG_HEIGHT;

const textEditorComponentRef = ref<InstanceType<typeof LfTextEditor> | null>(
  null
);

const dynamicFieldsLocalizedName = `"${t("COMMON.DYNAMIC_FIELDS")}"`;

const typedTag = ref("");
const hasRenderedTagList = ref(false);
const dropdownElement = ref<HTMLElement | null>(null);
const linkWord = ref<{ index: number; length: number } | null>(null);
const showLinkEditor = ref(false);
const linkEditorValue = ref("");
const linkModalPosition = ref({
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  height: 0
});
const handleSelectionChange = () => {
  const editorHasFocus =
    textEditorComponentRef.value?.editorInstance.hasFocus();

  if (showLinkEditor.value || !editorHasFocus) {
    return;
  }

  const editorInstance = textEditorComponentRef.value?.editorInstance;

  const selectionFormat = editorInstance.getFormat();

  if (!selectionFormat?.link) {
    return;
  }

  const contents: Delta = editorInstance.getContents();

  const content = contents?.ops.find(
    (content) =>
      content.attributes?.link &&
      content.attributes?.link === selectionFormat.link
  );

  if (!content) {
    return;
  }

  const cursorPosition = editorInstance.getSelection()?.index;
  editorInstance.setSelection(cursorPosition);

  const text = editorInstance.getText();

  let linkWordStart = text?.indexOf(content.insert);

  while (linkWordStart !== -1) {
    if (
      (cursorPosition >= linkWordStart &&
        cursorPosition <= linkWordStart + content.insert?.toString()?.length) ||
      0
    ) {
      break;
    }
    linkWordStart = text?.indexOf(content.insert, linkWordStart + 1);
  }

  if (linkWordStart === -1) {
    return;
  }

  linkWord.value = {
    index: linkWordStart,
    length: content.insert?.toString()?.length || 0
  };

  linkEditorValue.value = selectionFormat.link;
  showLinkEditor.value = true;
};

const dynamicFields = computed<EmailTemplateDynamicField[] | undefined>(
  () => getters["options/emailTemplates"]?.fields || undefined
);

const dynamicToolbar = computed(() => {
  return {
    container: compact([
      ...WYSIWYG_TOOLBAR_DYNAMIC,
      [
        { dynamicfields: dynamicFields.value?.map((field) => field.name) || [] }
      ],
      props.canUploadImage && ["image"]
    ]),
    handlers: {
      dynamicfields: function (value: string) {
        if (value) {
          insertDynamicField(value);
        }
      },
      link: () => {
        const editorInstance = textEditorComponentRef.value?.editorInstance;
        linkWord.value = editorInstance?.getSelection();
        linkModalPosition.value = editorInstance.getBounds(linkWord.value);
        if (!linkWord.value?.length) {
          return;
        }
        showLinkEditor.value = true;
      }
    }
  };
});

const modules = computed(() =>
  compact([
    {
      name: "mention",
      module: Mention,
      blotName: "styledMention",
      options: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["["],
        dataAttributes: ["tag", "name"],
        blotName: "styledMention",
        source: function (
          searchTerm: string,
          renderList: (a: EmailTemplateDynamicField[], b: string) => void,
          dynamicChar: string
        ) {
          if (dynamicChar !== "[") {
            return;
          }

          const values = dynamicFields.value?.map((field) => ({
            ...field,
            value: field.name
          }));

          if (!values?.length) {
            return;
          }

          typedTag.value = searchTerm;

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
            dropdownElement.value = document.querySelector(
              ".ql-mention-list"
            ) as HTMLElement;
            if (dropdownElement.value) {
              dropdownElement.value.style.height = maxTagsDropdownHeight + "px";
            }
            // has to be rendered the second time on the very first load
            if (!hasRenderedTagList.value) {
              renderList(values, searchTerm);
            }
            return;
          }

          const matches = values.filter((field) =>
            field.name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (dropdownElement.value) {
            dropdownElement.value.style.height =
              matches.length < 13
                ? matches.length * 30 + "px"
                : maxTagsDropdownHeight + "px";
          }

          renderList(matches, searchTerm);
        },
        onSelect: function (item: EmailTemplateDynamicField) {
          if (!textEditorComponentRef.value?.editorInstance) {
            return;
          }
          const editorInstance = textEditorComponentRef.value.editorInstance;
          const cursorPosition = editorInstance.getSelection()?.index;

          if (cursorPosition !== 0 && !cursorPosition) {
            return;
          }

          editorInstance.deleteText(
            cursorPosition - typedTag.value.length - 1,
            typedTag.value.length + 1
          );
          handleDynamicFieldInsertion(
            item,
            cursorPosition - typedTag.value.length - 1
          );
          editorInstance.setSelection(cursorPosition);
        }
      }
    },
    props.canUploadImage && {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file: File) => {
          emit("file:upload", file);
        }
      }
    }
  ])
);

const closeLinkModal = () => {
  showLinkEditor.value = false;
  linkEditorValue.value = "";
};

onClickOutside(modalRef as Ref<MaybeElement>, closeLinkModal);

const resetLinkState = () => {
  linkEditorValue.value = "";
  showLinkEditor.value = false;
  linkWord.value = null;
};

const removeLink = () => {
  if (!linkWord.value) {
    return;
  }
  textEditorComponentRef.value?.editorInstance.removeFormat(
    linkWord.value.index,
    linkWord.value.length
  );

  resetLinkState();
};

const saveLink = (link: string) => {
  linkEditorValue.value = link;

  if (!linkWord.value) {
    resetLinkState();
    return;
  }

  const linkWordLastLetterIndex = linkWord.value.index + linkWord.value.length;
  const editorInstance = textEditorComponentRef.value?.editorInstance;

  if (!link.length) {
    editorInstance.setSelection(linkWordLastLetterIndex);
    resetLinkState();
    return;
  }

  const word = editorInstance.getText(
    linkWord.value.index,
    linkWord.value.length
  );

  editorInstance.insertText(
    linkWord.value.index,
    word,
    "link",
    linkEditorValue.value
  );

  editorInstance.setSelection(linkWordLastLetterIndex);

  editorInstance.deleteText(linkWordLastLetterIndex, word.length);

  resetLinkState();
};

const handleDynamicFieldInsertion = (
  item: EmailTemplateDynamicField,
  position: number
) => {
  const editorInstance = textEditorComponentRef.value?.editorInstance;

  if (!editorInstance) {
    return;
  }

  editorInstance.insertEmbed(position, "styledMention", item);
  handleDenotationTags();
};

const insertDynamicField = (text: string) => {
  const item = dynamicFields.value?.find((field) => field.name === text);
  if (!text.length || !item) {
    return;
  }
  const editorInstance = textEditorComponentRef.value?.editorInstance;
  const cursorPosition = editorInstance.getSelection()?.index;
  handleDynamicFieldInsertion(item, cursorPosition);
  editorInstance.setSelection(cursorPosition + 1);
};

const handleDenotationTags = () => {
  const denotationCharNodes = document.querySelectorAll(
    ".ql-mention-denotation-char"
  );
  if (denotationCharNodes.length) {
    denotationCharNodes.forEach((denotationCharNode) =>
      removeDenotation(denotationCharNode)
    );
  }
};

const handleValueUpdate = (value: string) => {
  const contents: Delta =
    textEditorComponentRef.value?.editorInstance.getContents();
  const hasContent = contents?.ops.some(
    (content) => content.insert !== "\n" && content.insert !== ""
  );
  if (hasContent) {
    emit("update:modelValue", removeAttributesFromPayload(value));
    return;
  }

  emit("update:modelValue", "");
};

const handlePreparedEditor = () => {
  const visibleLength = 35;
  const dynamicFieldsElements = document.querySelectorAll(
    ".ql-dynamicfields .ql-picker-item"
  );

  dynamicFieldsElements.forEach((element) => {
    const contentLength = window.getComputedStyle(element, "::before").content
      .length;

    if (contentLength > visibleLength) {
      element.classList.add("overflow");
    }
  });
};
</script>
<style>
.ql-picker.ql-dynamicfields {
  width: 150px;
}

.ql-picker.ql-lineheight,
.ql-picker.ql-marginTop,
.ql-picker.ql-marginBottom {
  width: 24px;
  @apply relative;
}

.ql-picker.ql-lineheight svg,
.ql-picker.ql-marginTop svg,
.ql-picker.ql-marginBottom svg {
  width: 0 !important;
}

.ql-picker.ql-lineheight::before {
  width: 22px;
  height: 27.5px;
  background: url("/icons/LineHeight.svg") no-repeat;
  content: "";
  @apply absolute top-0 right-0 cursor-pointer z-3 pointer-events-none;
}

.ql-picker.ql-marginTop::before {
  width: 18px;
  height: 23.5px;
  background: url("/icons/MarginTop.svg") no-repeat;
  content: "";
  @apply absolute top-0 right-0 cursor-pointer z-3 pointer-events-none;
}
.ql-picker.ql-marginBottom::before {
  width: 18px;
  height: 23.5px;
  background: url("/icons/MarginBottom.svg") no-repeat;
  content: "";
  @apply absolute top-0 right-0 cursor-pointer z-3 pointer-events-none;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item::before,
.ql-snow .ql-picker.ql-marginTop .ql-picker-item::before,
.ql-snow .ql-picker.ql-marginBottom .ql-picker-item::before {
  content: attr(data-value);
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item:first-of-type::before,
.ql-snow .ql-picker.ql-marginTop .ql-picker-item:first-of-type::before,
.ql-snow .ql-picker.ql-marginBottom .ql-picker-item:first-of-type::before {
  content: "reset";
}

.ql-picker-options {
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 50 !important;
}

.ql-picker.ql-dynamicfields > span.ql-picker-label::before {
  content: v-bind(dynamicFieldsLocalizedName);
}

.ql-picker.ql-dynamicfields
  > span.ql-picker-options
  > span.ql-picker-item::before {
  content: attr(data-value);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ql-picker.ql-dynamicfields span.ql-picker-item {
  position: relative;
}
.ql-picker.ql-dynamicfields span.ql-picker-item.overflow::after {
  content: attr(data-value);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  white-space: normal;
  opacity: 0;
  min-width: fit-content;
  text-align: center;
  max-width: 200px;
  overflow-wrap: break-word;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  z-index: 1;
}
.ql-picker.ql-dynamicfields span.ql-picker-item.overflow:hover::after {
  opacity: 1;
  visibility: visible;
}

.ql-mention-list {
  @apply overflow-y-auto shadow-lg bg-white z-100 relative;
}

.ql-mention-list-item {
  @apply hover:bg-gray-50 text-headline text-sm py-1 px-2;
}

.ql-mention-list-item.selected {
  @apply bg-gray-50;
}

.quill-tag-business {
  background-color: rgba(255, 165, 0, 0.1);
}
.quill-tag-contact {
  background-color: rgba(0, 128, 0, 0.1);
}
.quill-tag-integration_partner {
  background-color: rgba(0, 255, 0, 0.1);
}
.quill-tag-personal_information {
  background-color: rgba(0, 191, 255, 0.1);
}

.quill-tag-other {
  background-color: rgba(255, 255, 0, 0.1);
}

.email-templates-edit .ql-container {
  height: auto;
  min-height: 100%;
  max-height: unset;
}
.email-templates-edit .ql-container .ql-editor {
  max-height: unset;
  height: inherit;
}

.email-headless .ql-toolbar {
  display: none;
}

.dynamic-field.email-headless .ql-container {
  border-radius: 0.375rem;
  border-color: rgb(229, 231, 235);
  border-top: 1px solid rgb(229, 231, 235) !important;
  min-height: 45px !important;
  @apply pl-2-5 py-2-5 pr-7;
}

.email-headless.email-headless__xs.ql-container {
  min-height: 15px !important;
  @apply pl-1-5 py-1-5 pr-3;
}

.dynamic-field.email-headless .ql-container .ql-editor {
  padding: 0;
  min-height: unset;
  @apply text-headline text-sm;
}

.email-headless .ql-editor.ql-blank::before {
  font-style: normal;
  opacity: 0.6;
  left: 9px;
}

.dynamic-field__body .ql-tooltip:has(.ql-preview) {
  display: none;
}
</style>
